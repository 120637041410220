import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./Account.module.css";
import { AccountContext } from "../../../auth";
import { ToastContext } from "../../../layout";
import { useNavigate } from "react-router-dom";
import {
  BsPower,
  BsMegaphone,
  BsPencil,
  BsFolderPlus,
  BsArrowRepeat,
  BsCreditCard
} from "react-icons/bs";
import { useOnClickOutside } from "../../../hooks";
import NotificationForm from "./NotificationForm/NotificationForm";
import EnvironmentEdit from "./EnvironmentEdit/EnvironmentEdit";
import FileUpload from "./FileUpload/FileUpload";
import { registerCAC, getCACSubject } from "../../../services/ReadyOneAPI";

export default function Account({ show, close }) {
  const [hasRendered, setHasRendered] = useState(false);
  const [showNotif, setShowNotif] = useState(false);
  const [showEnv, setShowEnv] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const notificationUsers = [
    "michell",
    "brooksjae",
    "fischerand",
    "simpsonbra",
    "lynchjk",
    "buituo",
    "scangoa",
    "clarkfw",
    "donovanja"
  ];
  const ref = useRef(null);
  const {
    setAuthenticated,
    setEnvironment,
    facades,
    email,
    firstName,
    lastName,
    username,
    logout,
  } = useContext(AccountContext);
  const { notifyInfo, notifySuccess, dismiss, delay } = useContext(ToastContext);
  const showNotification = notificationUsers.includes(username);
  const navigate = useNavigate();
  useOnClickOutside(ref, () => show && close());

  useEffect(() => {
    console.log(`ShowNotif: ${showNotif}`);
  }, [showNotif]);

  useEffect(() => {
    if (show) {
      dismiss();
      setHasRendered(true);
    }
  }, [show]);

  function assignCAC() {
    getCACSubject().then((result) => {
      registerCAC({email: email, cacSubject: result.subject}).then((result) => {
        console.log(result)
        notifySuccess(result, 1000);
      })
    });
  }

  function AccountItem({ name, email }) {
    return (
      <li className={styles.accountItem}>
        <span>{name}</span>
        <a>{email}</a>
      </li>
    );
  }

  function Item({ id, onClick, leftIcon, rightIcon, children }) {
    return (
      <a id={id} className={styles.item} onClick={onClick}>
        <span>{leftIcon}</span>
        {children}
        <span>{rightIcon}</span>
      </a>
    );
  }

  return (
    <>
      <div
        ref={ref}
        className={`${styles.dropdown} ${show ? styles.open : ""} ${
          hasRendered ? "" : styles.preventAnimation
        }`}
      >
        <ul className={styles.list}>
          <AccountItem name={`${firstName} ${lastName}`} email={email} />
          {showNotification && (
            <>
              <Item
                id="notification-button"
                leftIcon={<BsMegaphone />}
                onClick={() => {
                  setShowNotif(true);
                }}
              >
                Create Notification
              </Item>
              <Item
                id="notification-button"
                leftIcon={<BsPencil />}
                onClick={() => {
                  setShowEnv(true);
                }}
              >
                Edit Facades
              </Item>
            </>
          )}
          <Item
            id="notification-button"
            leftIcon={<BsFolderPlus />}
            onClick={() => {
              setShowUpload(true);
            }}
          >
            Upload File
          </Item>
          {facades.length > 1 && (
            <Item
              id="logoff-button"
              leftIcon={<BsArrowRepeat />}
              onClick={() => {
                close();
                notifyInfo("Switching Environments...", 500);
                delay(1000).then(() => {
                  navigate("/");
                  setEnvironment(null);
                });
              }}
            >
              Switch Facade
            </Item>
          )}
          <Item
            id="logoff-button"
            leftIcon={<BsCreditCard />}
            onClick={() => {
              close();
              assignCAC();
            }}
          >
            Register CAC
          </Item>
          <Item
            id="logoff-button"
            leftIcon={<BsPower />}
            onClick={() => {
              close();
              notifyInfo("Logging Off...", 1000);
              delay(1500).then(() => {
                navigate("/");
                logout(setAuthenticated);
              });
            }}
          >
            Log Off
          </Item>
        </ul>
      </div>
      {showNotification && (
        <NotificationForm show={showNotif} close={() => setShowNotif(false)} />
      )}
      {showEnv && (
        <EnvironmentEdit show={showEnv} close={() => setShowEnv(false)} />
      )}
      {showUpload && (
        <FileUpload show={showUpload} close={() => setShowUpload(false)} />
      )}
    </>
  );
}
